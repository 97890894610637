<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 内容 -->
    <div class="neir">
      <div class="neir-box">
        <div @click="backBtn" class="back">
          <SvgIcon
            :style="{
              fontSize: '12px',
              marginRight: '6px',
            }"
            iconClass="back"
          ></SvgIcon
          >Back
        </div>
        <div class="title">Notice</div>
        <div class="neir-main">
          <div class="item">
            <div class="item-da-tit">Large Headline</div>
            <div class="item-main">
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content textContent text Content text
            </div>
          </div>
          <div class="item">
            <div class="item-da-tit item-xiao-tit">1.1 Subheading</div>
            <div class="item-main">
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text
            </div>
          </div>
          <div class="item">
            <div class="item-da-tit item-xiao-tit">1.2 Subheading</div>
            <div class="item-main">
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text Content text
              Content text Content text Content text Content text
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import SvgIcon from "@/components/SvgIcon.vue";
export default {
  methods: {
    backBtn() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_notice") {
        this.$router.replace("/m_notice");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/notice") {
        this.$router.replace("/notice");
      }
    }
  },
  components: {
    Header,
    SvgIcon,
  },
};
</script>


<style lang="less" scoped>
.neir {
  width: 1030px;
  margin: 0 auto;
  padding: 0 15px;
  color: #12334c;

  .neir-box {
    padding: 20px 0 62px;
    .back {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 700;
    }
    .title {
      font-weight: bold;
      font-size: 30px;
      color: #12334c;
      margin-bottom: 40px;
    }

    .neir-main {
      .item {
        margin-bottom: 28px;
        .item-da-tit {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .item-xiao-tit {
          font-size: 18px;
        }

        .item-main {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>